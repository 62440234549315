import { createRouter, createWebHistory, RouterView } from "vue-router";
import { isAuthenticated, isLoggedIn, isNotLoggedIn } from './middlewares/account';
import { isOffice } from './middlewares/ip';
import { root } from './middlewares/redirect';
import store from '@/store';

const routes = [
  {
    path: '/',
    redirect: root,
  },
  {
    path: '/account',
    component: () => import('@/views/shared/account/Layout.vue'),
    children: [
      {
        path: 'login',
        component: () => import('@/views/shared/account/CenteredContent.vue'),
        props: {
          title: 'Inloggen',
        },
        beforeEnter: [isNotLoggedIn],
        children: [
          {
            path: '',
            name: 'account-login',
            component: () => import('@/views/account/login/Index.vue'),
          },
          {
            path: ':username',
            name: 'account-login-password',
            component: () => import('@/views/account/login/Password.vue'),
          },
        ],
      },
      {
        path: 'organisations',
        name: 'account-organisations',
        component: () => import('@/views/account/organisations/Index.vue'),
        beforeEnter: [isAuthenticated],
      },
    ],
    props: {
      hideNavigation: true,
    },
  },
  {
    path: '/company',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: '',
        name: 'companies',
        component: () => import('@/views/company/Index.vue'),
        meta: {
          displayName: 'Bedrijven',
        },
      },
      {
        path: 'new',
        name: 'new-company',
        component: () => import('@/views/company/Edit.vue'),
        meta: {
          displayName: 'Nieuw bedrijf',
        },
      },
      {
        path: ':id',
        name: 'edit-company',
        component: () => import('@/views/company/Edit.vue'),
        meta: {
          displayName: 'Bedrijf bewerken',
        },
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Index.vue'),
    beforeEnter: [isLoggedIn],
    meta: {
      displayName: 'Dashboard',
    },
  },
  {
    path: '/app-users',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: '',
        name: 'app-users',
        component: () => import('@/views/app-users/Index.vue'),
        meta: {
          displayName: 'App-gebruikers',
        },
      },
      {
        path: 'new',
        name: 'new-app-user',
        component: () => import('@/views/app-users/Edit.vue'),
        meta: {
          displayName: 'Nieuwe app-gebruiker',
        },
      },
      {
        path: ':id',
        name: 'edit-app-user',
        component: () => import('@/views/app-users/Edit.vue'),
        meta: {
          displayName: 'App-gebruiker bewerken',
        },
      },
    ],
  },
  {
    path: '/events',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: '',
        name: 'events',
        component: () => import('@/views/events/Index.vue'),
        meta: {
          displayName: 'Evenementen',
        },
      },
      {
        path: 'new',
        name: 'new-event',
        component: () => import('@/views/events/Edit.vue'),
        meta: {
          displayName: 'Nieuw evenement',
        },
      },
      {
        path: ':id',
        name: 'edit-event',
        component: () => import('@/views/events/Edit.vue'),
        meta: {
          displayName: 'Evenement bewerken',
        },
      },
    ],
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import("@/views/faq/Index.vue"),
    meta: {
      displayName: 'FAQ'
    }
  },
  {
    path: '/file-browser',
    name: 'file-browser',
    component: () => import("@/views/file-browser/Index.vue"),
    beforeEnter: [isLoggedIn],
    meta: {
      displayName: 'Documenten'
    }
  },
  {
    path: '/groups',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    meta: {
      displayName: 'Groepen'
    },
    children: [
      {
        path: '',
        name: 'Groepen',
        component: () => import("@/views/groups/Index.vue"),
      },
      {
        path: 'new',
        name: 'Nieuw',
        component: () => import("@/views/groups/Edit.vue"),
        props: {
          title: 'Groep aanmaken'
        }
      },
      {
        path: ':id',
        name: 'Bewerken',
        component: () => import("@/views/groups/Edit.vue"),
      }
    ]
  },
  {
    path: '/news',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: '',
        name: 'news',
        component: () => import('@/views/news/Index.vue'),
        meta: {
          displayName: 'Nieuws'
        }
      },
      {
        path: 'new',
        name: 'new-newsitem',
        component: () => import('@/views/news/Edit.vue'),
        meta: {
          displayName: 'Nieuw nieuwsbericht',
        },
      },
      {
        path: ':id',
        name: 'edit-newsitem',
        component: () => import('@/views/news/Edit.vue'),
        meta: {
          displayName: 'Nieuwsbericht bewerken',
        },
      },
    ]
  },
  {
    path: '/projects',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: '',
        name: 'projects',
        meta: { displayName: 'Projecten' },
        component: () => import('@/views/projects/Index')
      },
      {
        path: 'edit/:projectId',
        name: 'edit-project',
        meta: { displayName: 'Project aanpassen' },
        component: () => import('@/views/projects/Edit')
      },
      {
        path: 'new',
        name: 'new-project',
        meta: { name: 'Nieuw project' },
        component: () => import('@/views/projects/Edit')
      }
    ]
  },
  {
    path: '/project-items',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: ':projectId',
        name: 'project-items',
        meta: { displayName: 'Projectberichten' },
        component: () => import('@/views/project-items/Index')
      },
      {
        path: 'edit/:projectItemId',
        name: 'edit-project-item',
        meta: { displayName: 'Projectberict aanpassen' },
        component: () => import('@/views/project-items/Edit')
      },
      {
        path: 'new/:projectId',
        name: 'new-project-item',
        meta: { name: 'Nieuw projectbericht' },
        component: () => import('@/views/project-items/Edit')
      }
    ]
  },
  {
    path: '/admins',
    name: 'admins',
    component: () => import('@/views/admins/Index.vue'),
    beforeEnter: [isLoggedIn],
    meta: {
      displayName: 'Beheerders',
    },
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/roles/Index.vue'),
    beforeEnter: [isLoggedIn],
    meta: {
      displayName: 'Rollen & Rechten',
    },
  },
  {
    path: '/import',
    name: 'import',
    component: () => import('@/views/import/Index.vue'),
    beforeEnter: [isOffice],
    meta: {
      displayName: 'Importeren',
    },
  },
  {
    path: '/polls',
    beforeEnter: [ isLoggedIn ],
    component: RouterView,
    children: [
      {
        path: '',
        name: 'Polls',
        component: () => import('@/views/polls/Index.vue'),
        meta: {
          displayName: 'Polls'
        }
      },
      {
        path: 'new',
        component: () => import('@/views/polls/New.vue'),
        meta: {
          displayName: 'Nieuw'
        }
      },
      {
        path: ':id',
        component: () => import('@/views/polls/View.vue'),
        meta: {
          displayName: 'Weergeven'
        }
      }
    ]
  },
  {
    path: '/app',
    component: () => import('@/views/shared/account/Layout.vue'),
    props: {
      hideNavigation: true,
    },
    children: [
      {
        path: 'password',
        component: () => import('@/views/shared/account/CenteredContent.vue'),
        props: {
          title: 'Wachtwoord aanpassen',
        },
        children: [
          {
            path: ':type/:token',
            name: 'app-password',
            component: () => import('@/views/app/password/Index.vue'),
          },
          {
            path: 'success',
            name: 'app-password-success',
            component: () => import('@/views/app/password/Success.vue'),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeResolve(() => {
  store.dispatch('appHeader/clearActions');
});

export default router;
