<template>
  <aside
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0
           fixed-start bg-gradient-dark h-100 d-flex flex-column"
  >
    <div class="sidenav-header">
      <router-link
        class="sidenav-organization m-0 p-2"
        :title="currentOrganization?.name ?? ''"
        :to="{ name: 'account-organisations' }"
      >
        <img
          :src="currentOrganization?.logoUrl ?? ''"
          :alt="`${currentOrganization?.name ?? ''}`"
        />
      </router-link>

      <hr class="horizontal light mt-0 mb-2 w-100" />
    </div>

    <div class="sidenav-body flex-grow-1 overflow-y-scroll w-100">
      <sidenav-list />
    </div>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import SidenavList from "@/components/Layout/Sidenav/SidenavList.vue";

export default {
  name: "SidenavIndex",

  components: {
    SidenavList,
  },

  computed: {
    ...mapGetters('account', [
      'currentOrganization',
    ]),
  },
};
</script>

<style scoped lang="scss">
.navbar {
  flex-wrap:nowrap
}

.sidenav {
  justify-content: flex-start;

  &-header {
    height: unset;

    img {
      max-width: 80%;
    }
  }
  &-organization {
    display: flex !important;
    justify-content: center;
    align-items: center;

    &__image {
      aspect-ratio: 1;
      object-fit: contain;
    }

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  * {
    scrollbar-width: thin; /* Dunne scrollbar */
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
    
    &::-webkit-scrollbar {
      width: 5px; /* Minimaliseer de scrollbar breedte */
      border: none;
    }

    &::-webkit-scrollbar:hover {
      background: transparent;
      border: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.2); /* Kleur van het scroll-'thumb' */
      border-radius: 10px; /* Maak het afgerond */
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: transparent; /* Makes the scrollbar background invisible */
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track::hover {
      background-color: transparent; /* Verberg de track */
    }
  }
}

@media(max-width: 575px) {
  .sidenav {
    margin-bottom: 20px;
  }
}
</style>
